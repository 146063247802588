import React, { Component } from 'react';
import logo from './logo.svg';
import banner from './unknown\ (1).png'
import vouch1 from './vouch1.png'
import vouch2 from './vouch2.png'
import vouch3 from './vouch3.png'
import vouch4 from './vouch4.png'
import skill1 from './skill1.gif'
import skill2 from './skill2.gif'
import skill3 from './skill3.gif'
import iraq from './iraq.gif'
import './App.css';
import { Nav, Navbar, Form, FormControl, Button} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

class FAQ extends Component{
  render(){
    return(
      <div className="mx-5" style={{width:"500px"}}>
        <h1>FAQs</h1>
        <h5>How long will it take you to get my infernal cape</h5>
        <p>honestly I don't know, I'll die a few times </p>
        <h5>Is there any garuntee you're not going to just yoink my stuff</h5>
        <p>Yeah, yeah... of course</p>
        <h5>Will I get like banned for this</h5>
        <p><i>You should!</i> But no.</p>
      </div>
    );
  }
}

class Pricing extends Component{
  render(){
    return(
      <div className="mx-5" >
        <h1 style={{fontSize:"72px"}}>PRICING:</h1>
        <h2>Due to crippling sanctions by the United States government we are currently only able to trade in Iraqi dinars<img src={iraq}></img></h2>
      </div>
    )
  }
}

class Vouches extends Component{
  render(){
    return(
      <div className="mx-5" >
        <h1 style={{fontSize:"60px"}}>Vouches!</h1>
        <h4>Listen, we all understand that I'm pretty good at this game. But don't believe me? Check out some of the other capes that I've done!</h4>
        <div className="container">
          <img src={vouch1} style={{marginBottom:"20px"}}></img>
          <img src={vouch2} style={{marginBottom:"20px"}}></img>
          <img src={vouch3} style={{marginBottom:"20px"}}></img>
          <img src={vouch4} style={{marginBottom:"20px"}}></img>
        </div>
      </div>
    )
  }
}
class Display extends Component{
  render(){
    return(
      <div className="mx-5">
        <h1>Display of Talent</h1>
        <h4>I know what you're really here for. You want to get off to my immense skill at the game. Or maybe you just want proof at just how talented of a gamer I am. Check out the following proof</h4>
        <img src={skill1}></img>
        <img style={{marginLeft:"50px"}} src={skill2}></img><br>
        </br>
        <img style={{marginTop:"20px", marginLeft:"25%"}} src={skill3}></img>
      </div>
    )
  }
}

function App(){
  
    const Pwicing = React.useRef()
    const FAQUWU = React.useRef()
    const vouwuches = React.useRef()
    const dispway = React.useRef()
    function handlePricing(){
      Pwicing.current.scrollIntoView({behavior: 'smooth'})
    }
    function handleFAQ(){
      FAQUWU.current.scrollIntoView({behavior: 'smooth'})
    }
    function handlevouwuches(){
      vouwuches.current.scrollIntoView({behavior: 'smooth'})

    }
    function handleDisplay(){
      dispway.current.scrollIntoView({behavior: 'smooth'})
    }
    return (
      <div>
        <Navbar style={{backgroundColor: "orange"}}>
          <Navbar.Brand></Navbar.Brand>
          <Nav className="m-auto font-weight-bolder nav-fill" style={{color:"#FFFFFF", fontSize:"30px"}}>
            <Nav.Link style={{marginRight:"20px"}}>Home</Nav.Link>
            <Nav.Link style={{marginRight:"20px"}} onClick={handlePricing}>Pricing</Nav.Link>
            <Nav.Link style={{marginRight:"20px"}} onClick={handlevouwuches}>Vouches</Nav.Link>
            <Nav.Link style={{marginRight:"20px"}} onClick={handleFAQ}>FAQ</Nav.Link>
            <Nav.Link style={{marginRight:"20px"}} onClick={handleDisplay}>Display of Talent</Nav.Link>
          </Nav>
        </Navbar>
        <img className="rounded mx-auto d-block" src={banner}></img>
        
        <p className="rounded text-center lead mx-4 font-weight-bolder d-block" style={{fontSize:"40px"}}>After Acquiring his infernal cape a month ago, Tom Myers has decided that he can probably do it for you too. Click through the site to see some of the capes that he's done and order yours today</p>
        <hr style={{height:"5px"}}></hr>
        
        <div ref={Pwicing}>
          <Pricing></Pricing>
        </div>
        <hr style={{height:"5px"}}></hr>
        
        <div ref={vouwuches}>
          <Vouches></Vouches>
        </div>
        <hr style={{height:"5px"}}></hr>

        <div ref={dispway}>
          <Display></Display>
        </div>
        <hr style={{height:"5px"}}></hr>
        
        <div ref={FAQUWU}>
          <FAQ></FAQ>
        </div>
      </div>
    );
  }

export default App;



